// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-ships-agency-tsx": () => import("./../src/pages/ships-agency.tsx" /* webpackChunkName: "component---src-pages-ships-agency-tsx" */),
  "component---src-pages-stevedoring-tsx": () => import("./../src/pages/stevedoring.tsx" /* webpackChunkName: "component---src-pages-stevedoring-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-warehousing-tsx": () => import("./../src/pages/warehousing.tsx" /* webpackChunkName: "component---src-pages-warehousing-tsx" */)
}

